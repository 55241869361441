<template>
    <section class="app-ecommerce-details">

        <div class="mb-2">
            <router-link :to="{ name: 'list_interventions_from_equipment', params: { id: intervention.equipment_id } }">
                <b-button class="btn btn-dark m-05 mb-sm-0" type="button">Listado de intervenciones</b-button>
            </router-link>
            <router-link v-if="can('edit_intervention')"
                :to="{ name: 'edit_intervention', params: { id: this.$route.params.id } }">
                <b-button class="btn btn-dark m-05 mb-sm-0" type="button">Editar</b-button>
            </router-link>
            <router-link v-if="can('manage_intervention')"
                :to="{ name: 'manage_intervention', params: { id: this.$route.params.id } }">
                <b-button class="btn btn-dark m-05 mb-sm-0" type="button">Gestionar</b-button>
            </router-link>
            <b-button v-if="can('delete_intervention')" class="btn btn-danger m-05 mb-sm-0" type="button"
                @click="deleteIntervention">Eliminar</b-button>
        </div>

        <b-card no-body>
            <b-card-body>
                <b-row class="mx-0">
                    <b-col cols="12" md="6" lg="4" xl="3">
                        <label class="d-block font-weight-bolder">Nº Intervención</label>
                        <p>{{ intervention.id }}</p>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" xl="3">
                        <label class="d-block font-weight-bolder">Urgente</label>
                        <p>{{ intervention.urgent ? 'Si' : 'No' }}</p>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" xl="3">
                        <label class="d-block font-weight-bolder">Nº tag</label>
                        <p>{{ intervention.tag.code }}</p>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" xl="3">
                        <label class="d-block font-weight-bolder">Nº Equipo</label>
                        <p>{{ intervention.equipment.serial_number }}</p>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" xl="3">
                        <label class="d-block font-weight-bolder">Estado</label>
                        <p>
                            <b-badge :class="intervention.state.color" class="badge">
                                {{ intervention.state.name }}
                            </b-badge>
                        </p>
                    </b-col>
                    <b-col cols="12" v-if="intervention.description">
                        <label class="d-block font-weight-bolder">Descripción</label>
                        <p>{{ intervention.description }}</p>
                    </b-col>
                    <b-col cols="12" v-if="intervention.technician_solution">
                        <label class="d-block font-weight-bolder">Solución del Técnico</label>
                        <p>{{ intervention.technician_solution }}</p>
                    </b-col>
                    <b-col cols="12" md="6" lg="5" xl="4" v-if="intervention.intervention_photo">
                        <label class="d-block font-weight-bolder">Imagen</label>
                        <img v-if="intervention.intervention_photo"
                            :src="baseUrl + 'storage/' + intervention.intervention_photo"
                            class="w-100 image-responsive rounded-1 image-cover-height">
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </section>
</template>
  
<script>

import {
    BCard, BCardBody, BRow, BCol
} from 'bootstrap-vue'
import { baseUrl } from "@/constants/app"
import Ripple from 'vue-ripple-directive'
import { createHelpers } from "vuex-map-fields"
import { mapState } from "vuex"

import helpers from "@/resources/helpers/helpers"
import {INTERVENTIONS_LIST_EQUIPMENT} from "@/router/web/constants"
import Swal from "sweetalert2"

const { mapFields } = createHelpers({
    getterType: 'intervention/getField',
})

export default {
    name: "SheetView",
    directives: {
        Ripple,
    },
    components: {
        BCard,
        BCardBody,
        BRow,
        BCol,
    },

    computed: {
        ...mapState('intervention', ['intervention']),
    },
    data() {
        return {
            showLoading: false,
            baseUrl,
        }
    },
    created() {
        this.getIntervention()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        getIntervention() {
            this.showLoading = true

            this.$store.dispatch('intervention/getIntervention', {
                id: this.$route.params.id,
                relations: ['equipment', 'user', 'tag', 'state'],
            }).then(response => {
                this.showLoading = false

            }).catch(error => {
                this.showLoading = false
                console.log(error)
                this.$router.push({ name: 'list_equipments' })
                helpers.showToast(error.message, 'MonitorIcon', 'danger')
            })
        },
        deleteIntervention() {
            Swal.fire({
                title: "Está seguro de borrar la intervención?",
                text: "Una vez borrado no podrás recuperarla!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí!",
                cancelButtonText: "Cancelar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('intervention/delete', {
                        id: this.$route.params.id,
                    }).then(response => {
                        this.showLoading = false
                        this.$router.push({ path: INTERVENTIONS_LIST_EQUIPMENT + `/${this.intervention.equipment.id}/false` })
                    }).catch(error => {
                        this.showLoading = false
                        console.log(error)
                        helpers.showToast(error.message, 'MonitorIcon', 'danger')
                    })
                }
            })
        }
    }
}
</script>
  
<style lang="scss">@import "~@core/scss/base/pages/app-ecommerce-details.scss";</style>
  